@import '~react-redux-toastr/src/styles/index';
@import './Modal.scss';


body {
    overflow-y: auto;
    height: 100%;

    // * {
    //     outline: 1px solid red;
    // }

    &.detaillierung,
    &.anliegen {
        @media (min-height: 840px) {
            .routeContainer {
                overflow: hidden !important;
            }
        }
    }

    &.anliegen {
        @media (max-height: 839px) {
            overflow-y: scroll !important;
        }
    }

    &.blurred .sidebar {
        background-color: transparent;
    }
}

.overflowHidden {
    // @media (max-height: 840px) {
    overflow: hidden !important;
    // }
}

#root,
html {
    color: var(--main);
    background-color: var(--grey-light);
    // this is necessary for firefox
    height: 100%;
    // this could fix the errors with scroll ?
    // height: -webkit-fill-available;
    min-height: -webkit-fill-available;

    // height: 100vh; // this causes scroll on iphone 10
    @media (max-height: 400px) and (orientation: landscape) {
        height: calc(100vh + 2px);
    }

    @media (max-height: 850px) and (min-width: 896px) {
        .container-fluid {
            overflow: auto;
            justify-content: flex-start;
        }
    }
}

.fakeFocusable {
    display: block;
    position: relative;
    height: 0;
    color: transparent;

    &:hover,
    &:focus,
    &:active {
        color: transparent;
    }
}

.welcome .routeContainer>div {
    display: flex;
   
}

.routeContainer {
    width: 100%;
    position: relative;
    height: 100%;
    height: -webkit-fill-available;

    & > img {
        position: absolute;
        left: 26px;
        top: 26px;
        z-index: 3;
    }

    &>div {
        height: 100%;
        height: -webkit-fill-available;
    }

    // &:before {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     height: 100vh;
    //     width: 100vw;
    // }
    // overflow: hidden;
    &>.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 0;
        // z-index: 1;

    }

    .animationMask,
    .animationMaskInPage {
        // display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        // display: none;
        visibility: hidden;
        background-color: var(--grey-light);
    }
}

// .animationFinished .routeContainer {
// overflow: auto;
// }

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.contentContainer {
    @media (max-width: 575px) {
        padding: 0 0 73px;
    }

    &.expanded {
        .exRowTop {
            opacity: 0.5;
        }
    }
}

@media (max-width: 896px) {
    .blurred {
        .wunschberechnung_container {
            background-color: rgba(162, 165, 166, 0.2);
        }

        .main,
        .visChart,
        .selects button,
        .selects~img
            {
            filter: blur(3px);
            pointer-events: none;
        }

        .main:before {
            display: none;
        }
    }
}

.contentContainer>.container-fluid {
    overflow: auto;
}

.overflowHiddenOff {
    overflow: initial !important;
}

.customContainer {
    max-width: 1600px;
    margin: auto;
    height: auto;
    overflow: hidden;

    // @media (max-width: 575px) {
    margin-top: 32px;
    // }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

a, button {
    outline: none !important;

    .toggle {
        transition: fill .2s linear;
        transition: stroke .2s linear;
    }

    p {
        transition: color .2s linear;
        margin: 0;
    }

    &.filled {
        &:hover {
            .toggle {
                transition: fill .2s linear;
                transition: stroke .2s linear;
                fill: var(--green-light);
            }
        }
    }

    &:hover {
        text-decoration: none !important;

        p {
            color: var(--green-light);
        }

        .iconWrapper.filled {
            .toggle {
                transition: fill .2s linear;
                transition: stroke .2s linear;
                fill: var(--green-light);
                stroke: transparent;
            }
        }


    }
}

.strokeToggle {
    transition: stroke ease-in-out 0.3s;
    vector-effect: non-scaling-stroke;
}

.fillToggle {
    transition: fill ease-in-out 0.3s;
}

.crossFade {
    opacity: 0;
}

.basicLink:hover {
    svg .toggle {
        stroke: var(--grey) !important;
    }
}

.iconClickable {
    cursor: pointer;

    &:hover {
        .strokeToggle {
            stroke: var(--blue) !important;
        }
        .fillToggle {
            fill: var(--blue) !important;
        }
    }

    &:active, &:focus {
        border: none;
        outline: none;
        .strokeToggle {
            stroke: var(--blue-light) !important;
        }
        .fillToggle {
            fill: var(--blue-light) !important;
        }
    }
}

::placeholder {
    color: var(--grey);
    font-weight: 500 !important;
}

:-ms-input-placeholder {
    color: var(--grey);
    /* Internet Explorer 10-11 */
    font-weight: 500 !important;
}

::-ms-input-placeholder {
    color: var(--grey);
    /* Microsoft Edge */
    font-weight: 500 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

input {
    border-radius: 0;

    &:focus {
        outline: none;
    }

  


}
.invalid input {
    border-bottom: 2px solid var(--red) !important;
    color: var(--red) !important;
}

.headerBackButton {
    background-color: var(--grey-light);
    flex: 0 0 40px;
    // height: 80px;
    display: flex;
    align-items: center;
    padding: 2rem;
    justify-content: space-between;


    .chartTitleBack {
        button {
            display: flex;
            align-items: center;
            svg {
                margin-right: 1.2rem;
            }
        }
    }


    @media (min-width: 897px) {
        position: sticky;
        top: 0;
        background-color: var(--grey-light);
        z-index: 3;
        //margin-bottom: -2rem;
    }

    @media (max-width: 896px) {
        padding: 0 16px;
        z-index: 3;

        @media (max-height: 650px) {
            margin-top: -16px;
        }
    }

}

.modalBackButtons {
    @media (max-width: 896px) {
        padding: 16px !important;
    }
}
