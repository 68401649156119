@import '../../../styles/config.scss';

.inputText {
    display: block;
    width: calc(100% - 40px);
    border: none;
    padding: 15px 20px;
}

.inputText:focus {
    outline: none;
    border-bottom: 2px solid var(--blue-light) !important;
}

.textStandard {
    color: var(--dark);
    background: var(--light);
    border-radius: 10px;
    width: 100%;
}
/* Material text input */
.textMaterial {
    padding: 10px 0 5px;
    display: block;
    width: 100%;
    background: transparent;
    color: var(--main);
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 16px;
    border-bottom: 2px solid var(--grey);
    border-radius: 0;
    transition: border-bottom linear 0.2s;
    z-index: 2;

    
    &:focus,
    &:hover {
        border-bottom: 2px solid var(--main);
    }
    
    &.hasValue {
        color: var(--main);
    }

    @media (max-width: 896px) {
        height: 56px;
    }
}

.textMaterial + label {
    position: absolute;
    pointer-events: none;
    padding: 0;
    top: 21px;
    left: 0;
    // opacity: 0.8;
    transition: 200ms ease all;
    color: var(--grey);
    height: 17px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

// .textMaterial.hasValue + label,
// .textMaterial:focus + label {
//     top: -22px;
//     left: 0;
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 17px;
// }
