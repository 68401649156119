@import '../../styles/config.scss';

.dropdown.open {
    button div {
        transition: all ease-in-out 0.3s;
        color: var(--blue-light);
        font-weight: 700;
        border-color: var(--blue-light);
        // Glauk added this. Do we need it?
        .isCompleted {
            // color: var(--main);
            // border-color: var(--main);
        }
    }
}
.dropdown {
    transition: all ease-in-out 1s;
    max-height: 0;
}

.isOpened {
    // &:hover {
    max-height: 258px;
    // display: block;
    // @media (min-width: 2560px) {
    //     max-height: 34.7222vw;
    // }
}

// @keyframes animateCollapse {
//     from {
//         max-height: 600px;
//     }

//     to {
//         max-height: 48px;
//     }
// }

// .showWithoutAnimation {
//     transition: none;
//     max-height: 600px !important;
// }

// .contentContainer {
//     // opacity: 0;
// }

// // @media (min-width: 2560px) {
// //     margin-right: 3.1944vw;
// // }

// .animateOut {
//     // transition: all ease-in-out 1s;
//     animation: animateCollapse 2s linear;
//     // max-height: 48px;
    
// }

// .animateIn {
    // transition: all ease-in-out 1s;
    // max-height: 600px;

    // }
// }
