@import '../chartSettings.scss';

.blockCapital {
    // display: flex;
    // flex: 1; 
    // flex-direction: column;
    // justify-content: flex-end;
    height: 100%;
    visibility: hidden;

    .blockLabel {
        z-index: 1;
    }

    #barCapitalLabel {
        .arrowE {
            text-align: right;
        }

        .overviewTitles {
            @media (max-width: 896px) {
                display: none;
            }
        }
    }

    .tooltipTrigger {
        position: absolute;
        right: 0;
        top: 0;
        left: initial;
        margin-top: 12px;
        width: 26px;
        height: 20px;
        text-align: center;
        cursor: pointer;
        // overflow: hidden;
        z-index: 11;

        &.arrowE {
            transform-origin: 20px 8px;

            .toggle {
                stroke: var(--main);
            }
        }

        @media (max-width: 896px) {
            display: none;
        }

        svg {
            width: 12px;
        }

        &:hover {
            overflow: visible;

            .toggle {
                stroke: var(--main)
            }
        }

        &.rotated {
            &+.barSwitch {
                max-height: 200px;
                position: absolute;
                opacity: 1;
                transition: opacity ease-in-out 0.3s, max-heighttooltipTrigger linear 0.3s;
                z-index: 10;
                animation: opacity01Hide 0.3s;

                .switchBtn {
                    // transition: color ease-in-out 0.1s;
                    // color: var(--main);
                }
            }
        }
    }

    .barSwitch {
        width: 180px;
        max-height: 0;
        position: absolute;
        top: -3px;
        right: -9px;
        opacity: 0;
        // z-index: -1;
        // z-index: 0;
        opacity: 0;
        transition: opacity ease-in-out 0.3s, max-height linear 0.3s;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 27px 80px -20px rgba(38, 50, 56, 0.2), 0 4px 7.5px -10px rgba(38, 50, 56, 0.2), 0 9px 15px -15px rgba(38, 50, 56, 0.2), 0 18px 40px -30px rgba(38, 50, 56, 0.2);

        &:not(.structure) .verwendung {
            color: var(--blue-light) !important;
        }

        &.structure {
            .structure {
                color: var(--blue-light) !important;
            }
        }

        .switchBtn {
            height: 45px;
            margin-left: 0;
            margin-top: 0;
            padding: 6px 13px;
            width: 100%;
            // transform: scaleY(-1);
            background-color: #FFFFFF;
            box-shadow: inset 0 -1px 0 0 rgba(175, 175, 177, 0.16);
            color: var(--main);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.2px;
            line-height: 17px;
            text-align: left !important;
            transition: color ease-in-out 0.3s;

            &:hover {
                color: var(--blue-light);
                transition: color ease-in-out 0.3s;
            }
        }
    }

    .openCapitalButton {
        position: absolute;
        right: -4px;
        bottom: 2px;
        line-height: 14px;
        // margin-top: 8px !important;
        width: 40px;
        text-align: center;
    }

    #barCapitalNote {
        position: absolute;
        bottom: 15px;
        padding-right: 2px;
    }

    #barCapitalAll {
        width: 100%;
        background-color: #fff;
        // flex: 1 1 auto;
    }

    #barCapitalPerc {
        @extend .greyGradient;
        width: 100%;
        color: #fbfbfb;
        position: relative;


        &.enoughHeightFor1Row.inTooltip {
            .chfValue {
                visibility: hidden !important;
            }
        }

        p {
            color: white;
        }

        // flex: 1 1 auto;
    }

    #barBridging {
        @extend .greyGradient;
        // background-color: #838c87;

        p {
            color: white;
        }
    }

    #barSparguthaben {
        @extend .greyGradient;

        p {
            color: white;
        }
    }

    #barSparguthaben #barCapitalNote {
        display: none;
    }

    #barCapitalWithdrawal {
        @extend .mediumGreyGradient;
        width: 100%;
        // background-color: #969899;
        color: #fbfbfb;
    }

    .barcapitalPurchase {
        @extend .darkGreyGradient;
        width: 100%;
        // background-color: rgba(38, 50, 56, 0.8);
        color: #fbfbfb;
    }
}

.emptyBlock {
    display: flex;
    flex: 100 1 auto;
}

.invisibleContent * {
    visibility: hidden !important;
}