@import '../chartSettings.scss';


.blockEarlyPension {
    z-index: 1;

    #barEarlyPensionLabel {}

    #barEarlyPensionPerc {
        @extend .blueGradient;
        width: 100%;
        // background-color: #1B8DB3;
        // background: linear-gradient(#1B8DB3, #fff);
        color: var(--main);
    }

    #barBridgingPensionPerc {
        @extend .lightBlueGradient;
        width: 100%;
        // background-color: #1B8DB3;
        // background: linear-gradient(#1B8DB3, #fff);
        color: var(--main);
    }

    .blockTitle.bridgingPension {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        /* display: block; */
        white-space: nowrap;
    }

}

.hiddenContent>* {
    display: none !important;
}