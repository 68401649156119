@import './chartSettings.scss';

body.earlyPension {
    .blockPadding {
        padding: 0 2 * $blockPadding;
    }
}

.blockStyleRounded {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.Chart {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    background-color: white;
    color: var(--main);
    font-size: $font-size;
    line-height: 100%;


    .enoughHeightFor1Row.inTooltip {
        .percValue3s, .percValue2s, .blockValue {
            display: block;
            margin-top: 4px;
            visibility: visible !important;
        }
    }

    h1,
    p {
        margin: 0;
        padding: 0;
        text-align: left;
    }

    .inTooltip {
        p {
            // visibility: hidden !important;
            display: none;
        }
    }

    p.inTooltip {
        display: none;
        // visibility: hidden;
    }

    .blockLabelWrapper {
        display: flex;
    }

    .bottomLabel {
        position: absolute;
        // bottom: 24px;
        top: 0;

        // &.rotated {
        // transform: rotate(-90deg) translate(-100%, calc(-65%))
        // }
    }

    .blockWrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .addTeilButton2 {
        visibility: hidden;
        width: 100%;
        // display: none;

        &.forceVisible {
                visibility: visible;
                // display: inline-block;
        }
    }

    .blockFullHeight {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        justify-content: flex-end;
        max-height: $maxChartHeightDesktop;
        // min-height: $minChartHeight;
        min-height: 35vh;
        height: 100%;
        // margin-top: auto;
        opacity: 1;
        position: relative;
        padding-top: $chartTopPadding !important;

        &:not(.gsapInitial) {
            transition: max-width ease-in-out 0.6s;
        }


        @media (max-width: 896px) {
            max-height: $maxChartHeightMobile;
            // min-height: $minChartHeightMobilePortrait;
        }

        @media (max-height: 896px) {
            max-height: $maxChartHeightLowDesktop;

            @media (min-width: 896px) {
                max-height: 54vh;
            }
        }


        @media (max-width: 896px) and (orientation: landscape) {
            padding-top: 16px !important;
            max-height: 65vh;

            // @media (max-height: 600px) {
            // max-height: $maxChartHeightLandscape;
            // }
        }


        // min-width: 150px;
        // &.manipulating {
        //     opacity: 0.5;
        // }
    }

    // .blockActive {
    //     .blockElasticHeight {
    //         box-shadow: inset 0 0 25px -5px rgb(38, 50, 56);
    //     }
    // }

    .blockElasticHeight {
        flex: 1 1 auto;
        position: relative;
        transition: all ease-in-out 0.3s;

        &.loaded {
            min-height: 2px;
        }

        .forTooltipOnly {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            // width: 100%;
            width: calc(100% - 32px) !important;
            margin: auto;
            background-color: transparent !important;
        }
    }

    // .blockCapital {
    .blockElasticHeight {
        padding: 0px 16px;

        p.blockTitle {
            // margin-top: 18px;
            margin-top: 10px;
            word-break: break-all;
        }
    }

    // }

    .blockPadding {
        padding: 0 $blockPadding;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .blockLabel {
        // margin-left: 10px;
        // margin-top: 10px;
        width: 100%;
        margin-bottom: 25px;

        position: absolute;
        top: 0;

        @media (max-width: 896px) {
            top: 12px;
        }

        // &.rotated {
        //     z-index: 1;
        //     transform: rotate(-90deg) translate(calc(-93% - 10px), calc(-100% + 10px))
        // }

        &.hideCHF {
            .blockValue {
                visibility: hidden;
            }
        }
    }

    .blockTitle {
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: $font-size;
        margin-top: 10px;

        &.bridgingPensionTitle {
            font-size: 12px;
        }
        // color: var(--main);
    }

    .blockValue {
        font-family: Inter, sans-serif;
        font-weight: 300;
        font-size: $font-size;
        // color: var(--main);
        letter-spacing: -0.2px;
        line-height: 20px;
    }

    .blockContainer {
        display: flex;
        width: 100%;
        // border: 1px dotted lightgray;
    }

    .capitalMobile {
        margin-top: 16px;

        @media (max-width: 896px) {
            position: absolute;
            // top: 62px;
            left: 0;
            height: 52px;
            // width: 100%;
            margin-top: 0;
            padding-left: 1.6rem;
            
            .openCapitalButton {
                position: absolute;
                width: 35px;
                top: -3px;
                margin-left: 12px;
                margin-top: 0 !important;
            }

            @media (max-height: 650px) {
                top: 50px;
                margin-top: 16px;
            }

            @media (max-width: 896px) and (orientation: landscape) {
                display: none !important;
            }
        }
    }

    .chartLeft {
        display: flex;
        flex: 0.2 1 auto;
        max-width: 158px;
        min-width: 158px;
        flex-direction: column;
        justify-content: flex-start;
        // padding-right: 30px;

        // @media (max-width: 896px) {

        //     #barCapitalAll {
        //         display: none;
        //     }

        //     p:not(#barCapitalNote) {
        //         display: none;
        //     }
        // }

        .grid {
            padding-left: 10px;
            padding-right: 10px;
            z-index: 1;
            .addTeilButton2 {
                background:transparent;
                color: var(--blue);
                border-color: var(--blue);
            }
        }


        .chartLeftTop {
            display: flex;
            flex: 10 1 auto;
            max-height: $maxChartHeightDesktop;
            min-height: $minChartHeight;
            flex-direction: column;

            @media (max-width: 896px) {
                // max-height: initial;
                max-height: $maxChartHeightMobile;
                // min-height: $minChartHeightMobilePortrait;
            }

            @media (max-height: 896px) {
                max-height: $maxChartHeightLowDesktop;
            }

            // align-items: flex-end;
        }

        // button {
        .addTeilContainer {
            flex: 1 1 auto;
            // padding: 5px;
            text-align: center !important;
            // margin-right: 15px;
            margin-top: 16px;
            // max-height: 35px;
            font-weight: 700;

            button {
                margin: 0;
                padding: 5px
            }

            @media (min-width: 897px) {
                margin-left: -10px;
            }
        }
    }

    .scrollableContainer {
        overflow: auto;
        display: flex;
        flex: 1 1 auto;
    }

    .chartRight {
        overflow: auto;
        overflow-y: hidden;
        flex: 1 0 100%;
        display: flex;
        flex-direction: column;
        min-width: 900px;
        width: 100%;

        &.teilPensions4 {
            min-width: 1100px;
        }

        @media (max-width: 896px) {
            margin-left: -16px;
        }

        &.earlyPension {
            .segment0 .dotInvisible {
                margin-left: -145px;
            }
        }

        .chartRightTop {
            display: flex;
            flex: 10 1 auto;
            max-height: $maxChartHeightDesktop;
            min-height: $minChartHeight;
            height: 100%;
            justify-content: space-between;
            margin-left: $chartLeftPadding;
            margin-right: $chartLeftPadding;
            position: relative;

            &.spaceBetween {
                justify-content: space-between;
            }

            &.flexStart {
                justify-content: flex-start;
            }


            @media (max-height: 896px) {
                max-height: $maxChartHeightLowDesktop;
            }

            @media (max-width: 896px) {
                margin-top: 50px;
                // min-height: $minChartHeightMobilePortrait;

                @media (max-height: 896px) {
                    min-height: $minChartHeightMobileLandscape;
                    // max-height: $maxChartHeightMobile;
                    max-height: 35vh;
                }

                @media (max-height: 400px) and (orientation: landscape) {
                    min-height: $minChartHeightMobileLandscape;
                    margin-top: 0;
                    max-height: calc(100vh - 205px);
                }

                // max-height: 44%;
            }

        }
    }

    #barSalaryLabel {
        @media (max-width: 896px) and (orientation: landscape) {
            margin-bottom: 0;

            .blockValue {
                display: none;
            }
        }
    }


}
