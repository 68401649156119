@import '../../styles/config.scss';

.inputWrapper {
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    min-height: 56px;
    display: flex;
    width: auto;
    margin-bottom: 15px;
    // margin-bottom: 3.2rem;

    &.radio {
        min-height: 36px;
    }

    &.high {
        margin-top: 5.5rem;
        // @media (max-width: 575px) {
        //     margin-top: 2.8rem;
        // }
    }

    &.w-100 {
        width: 100%;

        .dropdown {
            width: 100%;
        }
    }
}
