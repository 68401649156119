.sliderWrapper {
    flex: 1;
    width: 100%;

    &.inactiveBridging {
        cursor: not-allowed;

        .slider_header,
        .track {
            pointer-events: none;
        }

        .slider_val_parent {
            opacity: 0.5;
            color: var(--grey) !important;
        }
    }

    &.inactive {
        cursor: not-allowed;

        .slider {
            background-color: var(--grey);
            box-shadow: 0 4px 6px 0 rgba(38, 50, 56, 0.2);
        }
    }

    &.infoText {

        &>div:not(.slider_header):not(.lohnsumme__button),
        .slider_val_parent {
            display: none;

        }

        .slider_header {
            flex-direction: column;

            p {
                text-align: left;
            }
        }

        .infoTextContent {
            margin-top: 1.4rem;
            font-size: 14px;
            font-weight: normal;
        }
    }

    &.notForThisDate {
        cursor: not-allowed;

        .slider {
            background-color: var(--grey);
            box-shadow: 0 4px 6px 0 rgba(38, 50, 56, 0.2);
        }

        .slider_header p {
            color: var(--red);
        }

        .slider_val_parent:last-child,
        .track,
        .slider_footer {
            pointer-events: none;
        }

        input {
            // color: var(--grey) !important;
        }
    }

    &.capitalBuyInSlider {
        input {
            // color: var(--main);
        }

    }

    input {
        color: var(--main);

        &:hover {
            border-color: var(--main);

            &~.slider_proc,
            &~.slider_val {
                border-color: var(--main);
            }
        }

        &:focus {
            border-color: var(--blue-light);

            &~.slider_proc,
            &~.slider_val {
                border-color: var(--blue-light);
            }
        }

        &:valid {
            &+.slider_proc {
                // color: var(--main);
            }
        }
    }


    .hasValue {
        color: var(--main);
        border-color: var(--main);

        &~.slider_proc,
        &~.slider_val {
            border-color: var(--main);
        }

        &+.slider_proc {
            color: var(--main);
        }
    }

    &.sliding {
        input {
            border-color: var(--blue-light);
            &~.slider_proc,
            &~.slider_val {
                border-color: var(--blue-light);
            }
        }
    }

    @media (max-width: 896px) {
        background-color: white;
        border-radius: 8px;
        margin-bottom: 6px;
    }

}

.slider_header {
    display: flex;
    // justify-content: space-between;
    margin: 0 4px 0 2px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 16px;

    @media (max-width: 896px) {
        margin: 0px 0 43px 0;
        font-size: 14px;
        justify-content: space-between;
    }

    // @media (min-width: 2560px) {
    //   margin: 0 1.3889vw;
    //   font-size: 1.1111vw;
    //   letter-spacing: -0.0139vw;
    //   line-height: 1.1111vw;
    // }
}

.slider_footer {
    display: flex;
    justify-content: space-between;
    margin: 17px 8px 0 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: var(--grey);

    // @media (min-width: 2560px) {
    //   margin: 1.1111vw 1.3889vw 0;
    //   font-size: 0.9722vw;
    //   line-height: 1.25vw;
    // }
    @media (min-width: 897px) {
        // margin-left: -10px;
    }
}

.slider_proc_parent {
    margin-right: 15px;
    margin-left: auto;
}

.sliderInputLabel {
    position: absolute;
    left: 0;
    top: 3px;
}

.slider_proc,
.slider_val {
    display: flex;
    align-items: center;
    // margin-right: 12px;
    font-weight: 500;
    text-align: right;
    // width: 40px;
    max-width: 143px;
    height: 27px;
    color: var(--main);
    border: none;
    border-bottom: 2px solid var(--grey);
    background-color: transparent;
    padding-bottom: 4px;

    input {
        padding: 0 0px 4px;
    }

    & :first-child {
        margin-right: 0;
    }
}

.slider_proc_parent,
.slider_val_parent {
    display: flex;
    align-items: center;
    // margin-right: 12px;
    font-weight: 500;
    text-align: right;
    // width: 40px;
    // max-width: 143px;
    height: 27px;
    color: var(--grey);
    border: none;
    border-bottom: var(--grey) solid 1px;
    background-color: transparent;
    position: relative;

    input {
        padding: 0 0px 4px;
    }

    & :first-child {
        margin-right: 0;
    }

    &.percOnly .slider_val {
        max-width: 40px;
    }

    // @media (min-width: 2560px) {
    //   margin-right: 0.8333vw;
    //   border-bottom: #1d2124 solid 1px;
    //   width: 2.7778vw;
    //   height: 2.0833vw;
    // }
    @media (max-width: 896px) {
        // width: 45%;
        width: calc(50% - 32px);
        max-width: 143px;

        input {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 897px) {
        // max-width: 120px;
        // max-width: 100px;
    }

    &:focus {
        outline-offset: 0;
        outline: 0;
    }

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--grey);
        font-weight: normal;

    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--grey);
        font-weight: normal;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--grey);
        font-weight: normal;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--grey);
        font-weight: normal;
    }

    &::placeholder {
        color: var(--grey);
        font-weight: normal;

    }
}

.sliderWrapper:not(.uncontrolled) .slider_val {
    width: 100%;
}


.slider {
    position: absolute;
    // margin-left: -11px;
    margin-top: -4px;
    z-index: 2;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 4px 6px 0 rgba(38, 50, 56, 0.2);
    background-color: var(--blue);

    @media (max-width: 896px) {
        //margin-top: -5px;
    }
}

.slider_line {
    position: absolute;
    height: 12px;
    z-index: 1;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: calc(100% + 2px);
        height: 4px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        background-color: var(--blue-light);
    }

}

.slider_line_progr {
    position: absolute;
    width: 100%;
    height: 12px;
    border-radius: 7px;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 7px;
        cursor: pointer;
        background-color: rgba(175, 175, 177, 0.4);
    }
}

.slider_val_parent {
    // margin-right: 0;
    margin-left: auto;

    @media (max-width: 896px) {
        margin-left: 0;

        &:last-child {
            margin-left: 16px;
            // margin-right: 34px;
        }
    }
}

@media (max-width: 896px) {

    .slider_val,
    .slider_val_parent {
        // max-width: 35%;
        margin-right: 0;
        justify-content: flex-end;
    }

    .middle {
        .slider_proc_parent {
            margin-left: auto;
        }
    }
}

.rentenant-main {
    .slider_val_parent {
        // margin-right: 14px;
    }

}

.slider_proc {
    // max-width: 48px;
    max-width: 56px;
}

.slider_proce_parent {
    max-width: 10px;
    ;
}
