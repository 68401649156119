@import '../../styles/config.scss';


.topRow {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-flow: wrap;
    min-height: 60px;
    // padding: 10px 0 10px 22px;
    align-items: center;

    @media (max-width: 991px) {}

    @media (max-width: 767px) {
        position: relative;
        padding: 10px 55px 22px 22px;
    }

    @media (max-width: 434px) {
        padding-right: 16px;
    }


    .hide {
        display: none;

        button {
            padding: 0 !important;
        }
    }

    button {
        background-color: transparent !important;
        border: none !important;
    }
}

.exRowTop {
    width: calc(100% - 95px);
    margin: auto;
    flex-wrap: wrap;
    border-radius: 8px;
    background-color: #FFFFFF;
    // margin-bottom: 22px;
    transition: all ease-in-out .2s;
    // box-shadow: 0 18px 60px -30px rgba(38, 50, 56, 0.2);
    transition: all, ease-in-out .3s, opacity ease-in-out .6s;

    @media (max-width: 991px) {
        width: calc(100% - 32px);
    }

    @media (max-width: 1220px) {
        width: calc(100% - 70px);
    }

    @media (max-width: 434px) {
        border-radius: 0;
        margin-bottom: 10px;
        width: 100%;
        padding: 0;
    }

    .column {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.2px;
        line-height: 22px;
        padding: 18px 0 0;

        @media (max-width: 896px) {
            font-size: 14px;
        }
    }


    // &:hover {
    // box-shadow: 0 27px 100px -20px rgba(38, 50, 56, 0.2), 0 4px 15px -10px rgba(38, 50, 56, 0.2), 0 9px 30px -15px rgba(38, 50, 56, 0.2), 0 18px 60px -30px rgba(38, 50, 56, 0.2);
    // }

    .moreRow {
        position: relative;
        max-height: 0;
        transition: max-height ease-in-out 0.3s, opacity linear 1s;
        overflow: hidden;
        flex-flow: wrap;
        border-bottom: 1px solid var(--grey03);
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;

        @media (max-width: 896px) {
            font-size: 14px;
            padding-left: 0 !important;
        }


        &:after {
            background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.1));
            content: "";
            width: 200%;
            height: 100%;
            position: absolute;
            transition: left ease-in-out .6s;
            left: -100%;
        }

        @media (max-width: 991px) {
            padding-left: 16px;
        }

        .col-3 {
            padding: 0;
            min-width: 130px;
        }

        .moreContainer {
            padding: 10px 0 10px 16px;
            width: 100%;
            display: flex;

            @media (max-width: 991px) {
                padding: 10px 0 10px 8px;
            }

            .title.column {
                margin-bottom: 10px;
                margin-left: 0;
            }

            .row {
                width: 100%;
                margin-top: 36px;
                margin-left: 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        &.active {
            height: auto;
        }
    }

    &.expanded {
        opacity: 1 !important;


        .moreRow {
            max-height: 400px;
            transition: max-height ease-in-out .6s, opacity linear 1s;

            &:after {
                left: 100%;
                transition: left ease-in-out .6s;
            }

            @media (max-width: 767px) {
                max-height: 1000px;
            }
        }

        .exRow {
            box-shadow: none;

            // &:hover {
            // box-shadow: 0 27px 100px -20px rgba(38,50,56,0.2), 0 4px 15px -10px rgba(38,50,56,0.2), 0 9px 30px -15px rgba(38,50,56,0.2), 0 18px 60px -30px rgba(38,50,56,0.2);
            // }
        }
    }
}

button.moreDetails {
    padding: 0;
    cursor: pointer;
    display: block;
    height: 24px;
    width: 24px;


    &:focus {
        outline: 0;
    }

    svg path {
        stroke: var(--main) !important;
        transition: stroke .2s linear;
    }

    &:hover {
        svg path {
            transition: stroke .2s linear;
            stroke: var(--green-light) !important;
        }
    }
}

.arrowE {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: transform ease-in-out 0.3s;

    &.rotated {
        transform: rotate(180deg);
    }

    &.left {
        transform: rotate(90deg);
    }

    svg {
        width: 16px;
        height: auto;
    }
}

.columnIn {
    display: flex;
    flex-flow: column;
    padding: 0 15px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

.subTitle {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #7a7a7a;
    color: var(--grey);
}

.positionTitle {
    color: #7a7a7a;
    font-size: 13px;
}

.col2 {
    min-width: 75px;
    margin-left: auto;
}

.col7 {
    margin-left: 28px;
}

.col1 {
    max-width: 50%;
}