.visualisationPage {

    &.react-draggable-transparent-selection {
        @media (max-width: 896px) {

            // .backButton,
            .header,
            .capitalMobile,
            .helpIconMobile,
            .showSlidersButton,
            .blockValue,
            .chartRightTop {
                visibility: hidden !important;
                opacity: 0 !important;
            }

            .select-list {
                opacity: 0; //works better here
            }

            .monthLabel {
                visibility: hidden;
                // position: absolute;
                // top: -115pxelect
                // left: 0;
                // right: 0;
                // transform: translateX(0px);
                // top: -160px;
                // font-size: 21px;
                // white-space: nowrap;
                // line-height: 24px;
                // font-weight: bold;
                // width: 215px;

                span {
                    font-weight: normal;
                }

                span.d-none {
                    display: block !important;
                }
            }
        }
    }

    &.dragStarted,
    &.dragStartedFake {

        .blockFullHeight {
            opacity: 0.6 !important;
        }

        &:not(.react-draggable-transparent-selection) {


            .blockFullHeight {
                // opacity: 0.3;
                animation: opacityAnime0206 1s infinite;
                animation-timing-function: ease-in-out;
                animation-direction: alternate-reverse;
            }

            @keyframes opacityAnime0206 {
                from {
                    opacity: 0.2;
                }

                to {
                    opacity: 0.6;
                }
            }

        }




        .blockFullHeight {
            opacity: 0.5;
        }

        .chartRightTop {
            // min-height: 175px !important;
        }

    }


    .visualisation {
        height: 100%;
        height: -webkit-fill-available !important;
        // overflow: auto;

        overflow: scroll;

        .sidebar {
            // padding: 28px 0;
            flex: 0;

            .neustartLabel {
                cursor: pointer;
                height: 40px;
                width: 0;

                // margin-bottom: 16px;
                @media (max-width: 896px) {
                    // width: 24px;
                    width: 38px;
                }

                &:hover {
                    @media (min-width: 897px) {
                        width: 130px;
                        background-color: white;

                        .backButton {
                            // width: 80px;
                            visibility: visible;
                            opacity: 1;
                            transition: all ease-in-out 0.6s;
                            text-align: left;
                        }
                    }

                    .backButton {
                        // width: 80px;
                        visibility: visible;
                        opacity: 1;
                        transition: all ease-in-out 0.6s;
                        text-align: center;
                    }


                }
            }

            .backButton {
                line-height: 32px;
                width: 0;
                visibility: hidden;
                // transition: all ease-in-out 0.3s;
                opacity: 0;
            }
        }

        &.modal {
            .helpIconMobile {
                display: none;
            }

            .sidebar {
                z-index: 1;
            }

            .visChart {

                .handleWrapper {
                    z-index: 2;
                }

                .addTeilButton {
                    display: flex;
                    max-width: 145px;
                    margin: auto;
                    padding: 5px 18px;
                    max-width: 145px;
                    min-height: 35px;
                    margin: auto;
                    margin-top: 80px;
                    margin-bottom: 36px;

                    @media (max-width: 896px) {
                        margin-top: 45px;
                        margin-bottom: 40px;
                        min-height: auto;
                    }

                }


                .header {
                    @media (max-width: 896px) {
                        position: sticky;
                        top: 0;
                        left: 0;
                        // width: 100%;
                        right: 0;
                        // height: 108px;
                        z-index: 3;
                    }

                    .tabs {
                        &:after {
                            content: "";
                            height: 1px;
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            display: block;
                            background-color: var(--grey);
                        }
                    }

                    .mdc-tab {
                        width: auto;
                        margin: auto;

                        &:first-child {
                            margin-right: 12px !important;
                        }
                        &:last-child {
                            margin-left: 12px !important;
                        }
                    }

                    .closeIcon {
                        cursor: pointer;

                        svg path {
                            fill: var(--grey);
                        }
                    }

                    p {
                        display: none;
                    }
                }

                .topControls {
                    position: relative;
                    top: initial;
                    overflow: auto;

                    @media (max-width: 896px) {
                        overflow: initial;
                        margin-top: 20px;
                    }
                }

                .tabs {
                    @media (max-width: 896px) {
                        display: flex;
                        position: fixed;
                        left: 0;
                        right: 0;
                        background-color: var(--grey-light);
                        top: 60px;
                        height: 40px;
                    }
                }
            }
        }


        @media (max-width: 896px) {
            // &:not(.modal) {
            //     padding: 0 16px 0;
            // }

            box-sizing: content-box;

            .select {
                margin-bottom: 22px;

                @media(orientation: landscape) {
                    margin-bottom: 8px;
                }

            }

            .helpIconMobile {
                position: fixed;
                left: 16px;
                bottom: 18px;
                z-index: 2;
                box-shadow: 2px 2px 14px 0 rgba(38, 50, 56, 0.4);
                background-color: transparent !important;
                border-radius: 50%;
            }

            .addButton {
                display: inline-block;
                text-align: center;
                line-height: 43px;
                height: 48px;
                width: 48px;
                border-radius: 28px;
                background-color: white;
                // z-index: 20;
                z-index: 10;
                box-shadow: 5px 5px 14px 0 rgba(38, 50, 56, 0.4);
                position: fixed;
                right: 15px;
                bottom: 18px;

                @media (orientation: landscape) {
                    display: none;
                }



            }

            // .showSlidersButton {
            z-index: 2;
            // min-height: 100vh;
            display: flex;
            flex-direction: column;
            // }
        }

        @media (max-width: 896px) and (orientation: landscape) {
            padding: 0 16px;
        }

        @media (min-width: 897px) {
            // @media not (min-width: 375px) not (max-height: 896px) {

            // .select-list {
            .sidebar {
                position: absolute;
                left: 0;
                height: 100%;
                background-color: var(--grey-light);
                width: auto;
                padding: 28px 0;

                .selects {
                    // bottom: 0;
                    // top: 0;
                    // margin-top: calc(45vh - 20px);
                    /* margin: auto; */
                    width: 80px;
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    padding-left: 28px;
                    max-height: calc(100vh - 207px);

                    .select {
                        z-index: 999;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        transition: all ease-in-out 0.3s;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        img {
                            margin: 13px 20px 0px 10px;
                        }

                        .dropdown {
                            background-color: white;
                            // padding-bottom: 19px;
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;

                            button {
                                opacity: 0
                            }
                        }

                        &.hidden {
                            width: 0;
                        }

                        &.visible {
                            width: 330px;
                            border-radius: 8px;
                        }

                    }

                    .visible {
                        background-color: white;
                        // padding-bottom: 14px;

                        .dropdown {
                            visibility: visible;
                            width: 290px;
                            opacity: 1;
                            // transition: width .2s linear;

                            button {
                                opacity: 1;
                                transition: opacity .1s linear;
                                background-color: transparent;
                                transition-delay: .2s;
                            }
                        }
                    }

                    .hidden {
                        background-color: transparent;
                        // transition: background-color .2s linear .2s;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;

                        .dropdown {
                            width: 0px;
                            height: 0;
                            visibility: hidden;
                            opacity: 0;
                            background-color: transparent;
                            transition: width .2s, visibility .1s linear, opacity .1s linear, background-color .1s linear;
                        }
                    }
                }
            }
        }
    }
}


.Box {
    width: 150px;
    height: 150px;
    background: #90CAF9;
    text-align: center;
    line-height: 150px;
    font-size: 60px;
    color: #0D47A1;
    // border: 1px solid #0D47A1;
    margin: 10px auto;
    cursor: pointer;
}

p {
    text-align: center;
}

.mdc-tab__content {
    color: var(--main);
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 6px;
}

.visualisation:not(.modal) {
    .header {
        @media (max-width: 896px) {
            .chartTitle {
                margin-bottom: 0;
            }
            
            @media (orientation: landscape) {
                display: none;
            }
        }
    }
}

.visChart {
    position: relative;
    width: 100%;
    // height: 100%;
    // height: 100vh;
    height: -webkit-fill-available;
    height: 100%; // for firefox, to send chart controls to the bottom and display whole box shadow
    padding-left: 10rem;
    padding-right: 4rem;
    display: flex;
    flex-direction: column;

    @media (min-width: 897px) {
        // height: auto; // animating capital pillar makes bottom sliders jump
        height: 100%;
    }

    @media (max-width: 896px) {
        flex: 1 1 auto;
        padding-left: 0;
        padding-right: 0;
        z-index: 1;
    }

    .addTeilButton {
        display: none;
    }


    .header {
        background-color: white;
        //flex: 0 0 80px;
        // height: 10rem;
        display: flex;
        align-items: center;
        padding: 2rem 4rem 0;
        justify-content: space-between;

        border-top-right-radius: 1.2rem;
        border-top-left-radius: 1.2rem;

        border-bottom: 1px solid var(--grey-light);

        //overflow: hidden;

        @media (min-width: 897px) {
            position: sticky;
            top: 0;
            background-color: white;
            z-index: 2;
        }

        @media (max-width: 896px) {
            padding: 0;
            z-index: 2;
            padding: 1.6rem;
            
            &.transparent {
                background-color: transparent;
            }

            @media (max-height: 650px) {
                margin-top: -16px;
            }
        }

        p {
            color: var(--color-main);
            font-size: 22px;
            font-weight: 600;
            line-height: 22px;


            @media (max-width: 896px) {
                text-align: left;
                padding: 0;
                line-height: 1.2em;
            }
        }

        .tabs {
            display: flex;
            height: 8rem;

            @media (max-width: 896px) {
                display: none;
            }

            button {
                height: 100%;
                padding: 0;


            }
        }
    }
}
