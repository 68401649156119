@import '../../../styles/config.scss';

.inputLabel {
    display: block;
    position: absolute;
    top: 21px;
    left: 0;
    width: 100%;
    // width: calc(100% - 40px);
    border: none;
    font-size: 1.6rem;
}
