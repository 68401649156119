
.loading {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  justify-content: center;

  p {
    position: relative;
    top: calc(50vh - 50px);
    text-align: center;
    color: white;
    font-size: 20px;
    white-space: pre;
  }

  .loader {
    svg {
      height: 120px;
      width: 120px;
      margin: auto;
      display: block;
      fill: rgba(197, 215, 135, 0.42);
    }
  }

  .logo {
    position: absolute;
    left: 0;
    right: 0;
    top: 44px;
    margin: auto;
    width: 63px;

    svg {
      width: 100%;
      // fill: rgba(2, 110, 185, 0.42);
      fill: rgba(2, 110, 185, 1);
    }
  }

  .wrapper {
    position: relative;
    position: relative;
    top: calc(50vh - 100px);


  }

}
