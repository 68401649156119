.autosuggestContainer {
    max-width: 476px;
    min-width: 476px;
    height: 296px;
    background-color: white;
    position: relative;
    border-radius: 8px;

    // @media (min-width: 2560px) {
    //   max-width: 33.0556vw;
    //   width: 33.0556vw;
    //   height: 20.5556vw;
    //   border-radius: 0.5556vw;
    // }
    @media (max-width: 896px) {
        height: 240px;
        max-width: 90vw;
        min-width: 90vw;
        padding: 0 15px;
        margin-top: 9px;
    }
}

.react-autosuggest__container {
    margin: 32px 48px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    // @media (min-width: 2560px) {
    //   margin: 2.2222vw 3.3333vw;
    // }
    @media (max-width: 896px) {
        margin: 0;
    }
}

.react-autosuggest__input {
    width: auto;
    height: 48px;
    border: none;
    border-bottom: 2px solid var(--grey);
    color: var(--grey-dark);
    font-size: 16px;
    font-weight: bolder;
    border-radius: 0;

    // @media (min-width: 2560px) {
    //   width: 26.3889vw;
    //   height: 3.3333vw;
    //   border-bottom: 0.1389vw solid var(--grey);
    //   font-size: 1.1111vw;
    // }

    @media (max-width: 896px) {
        height: 65px;

    }

    &:focus {
        outline-offset: 0;
        outline: 0;
    }

    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--grey);
        font-weight: normal;

    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--grey);
        font-weight: normal;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--grey);
        font-weight: normal;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--grey);
        font-weight: normal;
    }

    &::placeholder {
        color: var(--grey);
        font-weight: normal;

    }
}

#react-autowhatever-1 {
    max-height: 154px;
    overflow-y: auto;
    height: 153px;
    width: calc(100%);
    margin: 12px 0 48px;
    overflow: hidden;
    // @media (min-width: 2560px) {
    //   max-height: 10vw;
    //   height: 10vw;
    //   width: calc(100% + 1.3889vw);
    //   margin: 1.6667vw 0 3.3333vw;
    // }

    & ul {
        padding-left: 0;

        & li {
            list-style-type: none;
            color: var(--grey-dark);
            font-size: 16px;
            line-height: 16px;
            padding: 7px !important;
            cursor: pointer;
            // @media (min-width: 2560px) {
            //   font-size: 1.1111vw;
            //   line-height: 1.1111vw;
            //   padding-bottom: 1.1111vw;
            // }
        }
    }
}

.autosuggestButton {
    position: absolute;
    // bottom: 48px;
    top: 160px;
    left: 163px;

    // @media (min-width: 2560px) {
    //   bottom: 3.3333vw;
    //   left: 11.3194vw;
    // }


    &.fullWidthBtn {
        left: 99px;
        & .button {
            width: auto;
            text-transform: uppercase;
        }
        @media (max-width: 896px) {
            bottom: 24px;
            left: 0;
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: 896px) {
        // bottom: 24px;
        top: 110px;
        left: 0;
        width: 100%;
        text-align: center;
    }

    & .button {
        width: 151px;
        padding: 19px 48px;
        margin: 0;
        // @media (min-width: 2560px) {
        //   width: 10.4861vw;
        //   padding: 1.3194vw 3.3333vw;
        // }
    }
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding-top: 10px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: var(--green-light);
    color: white !important;
    font-weight: bold;
    border-radius: 0;
}
