  // ---------- VARS ---------- 
  $minBlockWidth: 128px;
  $border-radius: 10px;
  //   $chart-pad-x: 20px;
  //   $chart-pad-y: 20px;
  // $pad-x: 10px;
  // $pad-y: 10px;
  $font-size: 14px;
  $blockPadding: 5.5px;

  $chartTopPadding: 75px;
  $headerHeight: 87px;
  $headerHeightMobile: 70px;
  $titleHeightMobile: 64px;

  $gridHeight: 170px;
  $gridHeightMobile: 220px;
  $controlsHeight: 220px;

  $chartPaddingDesktop: 38px;
  $chartPaddingTablet: 24px;
  $chartPaddingMobile: 24px;

  $minChartHeight: 285px;
  $minChartHeightMobilePortrait: 230px;
  $minChartHeightMobileLandscape: 130px;
  // 537px - 
  $sumDesktop: $chartPaddingDesktop * 2+$gridHeight+$headerHeight+$controlsHeight+15;
  $sumMobilePortrait: $gridHeightMobile+$headerHeight+$titleHeightMobile;

  $maxChartHeightDesktop: calc(100vh - #{$sumDesktop + 14px});
  //   $maxChartHeightTablet: calc(100vh - #{$sumMobilePortrait});
  $maxChartHeightMobile: calc(100vh - #{$sumMobilePortrait});
  $maxChartHeightLandscape: 70vh;
  $maxChartHeightLowDesktop: 30vh;
  //   $maxChartHeightMobile: 44%;

  $chartLeftPadding: 35px;
  $chartRightPadding: 35px;

  // ---------- /VARS ----------


  .blueGradient {
      background: linear-gradient(0deg, #B0D7E5 0%, #6BB5CE 100%);
  }

  .lightGreyGradient {
      background: linear-gradient(0deg, #E1E4E6 0%, #D2D4D6 100%);
  }

  .lightBlueGradient {
      background: linear-gradient(0deg, #CFE7F0 0%, #A7D3E2 100%);
  }

  .mediumGreyGradient {
      background: linear-gradient(180deg, #969899 0%, #B5B7B8 100%);
  }

  .greyGradient {
      background: linear-gradient(180deg, #808385 0%, #9A9EA1 100%);
  }

  .darkGreyGradient {
      background: linear-gradient(180deg, #5F6467 0%, #757B80 100%);
  }
