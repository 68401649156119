@import '../../../styles/config.scss';

.months {
  width: 100%;
  display: flex;
  flex-flow: wrap;

  @media (max-width: 896px) {
    justify-content: center;
    overflow-y: auto;
    padding-top: 0.9rem;
    //max-height: 18vh;
  }
}

.months .month {
  width: 33.333%;
  height: 43px;
  font-size: 16px;
  color: var(--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 100ms ease;
  font-weight: 500;
  letter-spacing: -0.2px;

  @media (min-width: 897px) {
    height: 54px;
  }

  // @media (min-width: 2560px) {
  //   width: 8.75vw;
  //   height: 3.75vw;
  //   font-size: 1.1806vw;
  //   border-radius: 0.3472vw;
  // }
}
@media (min-width: 896px) {
  .months .month:hover {
    // background-color: var(--grey);
    // color: var(--green-dark);
    color: white;
    background-color: var(--blue-light);
    font-weight: 600;
  }
}

.months .month:active {
  // color: var(--green-dark);
  color: white;
  background-color: var(--blue-light);
  font-weight: 600;
  // background-color: var(--green-hover);
}
