@import '../../styles/config.scss';

.dropdownCollapse {
    color: var(--grey);
    // min-height: 48px;
    min-height: 40px;
    // height: 100%;
    //height: 80px;
    //position: absolute;
    right: 0;
    z-index: 7;
    max-height: 48px;
    overflow: hidden;
    transition: all ease-in-out 1s;

    @media (max-width: 896px) {
        display: none;
        width: 380px;
        margin-right: 0;
        max-width: 100vw;
        right: 100px;
        // pointer-events: none;
    }
}


.collapseButton {
    white-space: pre-wrap !important;
    white-space: -moz-pre-wrap !important;
    white-space: -pre-wrap !important;
    white-space: -o-pre-wrap !important;
    word-wrap: break-word !important;
    // background-color: initial !important;
    background-color: transparent !important;
    border: none;
    color: var(--grey);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 16px;
    padding: 9px 0 8px !important;
    height: 100%;

    @media (min-width: 896px) {
        width: 100%;
        text-align: left;

        .label {
            margin-left: 0;
            width: 100%;
        }
    }

    &:focus {
        box-shadow: none !important;
        border: none;
        outline: 0;
    }

    &:hover {
        //div {
        //    &:not(.isCompleted) {
        //        color: var(--blue);
        //
        //        &~.arrow {
        //            border-bottom: 2px solid var(--blue) !important;
        //            border-right: 2px solid var(--blue) !important;
        //        }
        //    }
        //}
    }
}

.label, .labelLike {
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 16px;
    margin: auto;
    height: 20px;
    transition: all ease-in-out 0.3s;

    &.isOpened {
        font-weight: bold;
        color: var(--main); // display: block;
    }

    &.isCompleted {
        font-weight: bold;
        color: var(--main) !important;
    }
}

.labelContainer {
    position: relative;
    // height: 50px;
    cursor: pointer;
    height: 100%;
    width: 276px;
    color: var(--grey);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 16px;
    z-index: 2;

    @media (max-width: 896px) {
        margin-left: auto;
    }
}

.contentContainer {
    //position: absolute;
    width: 356px;
    border-radius: 8px;
    //background-color: #FFFFFF;
    // box-shadow: 0 10px 30px -20px rgba(1, 50, 62, 0.3);
    //box-shadow: -10px 0 30px -20px rgba(1,50,62,0.3);
    top: 54px;
    right: 30px;
    z-index: 7;

    @media (max-width: 896px) {
        right: 0;
        top: 0;
        width: 100%;
        position: fixed;
    }
}

.activeArrow {
    transform: rotate(-135deg) translate(-5px, -5px) !important;
    border-bottom: 2px solid var(--blue-light);
    border-right: 2px solid var(--blue-light);
}

.arrow {
    height: 10px;
    width: 10px;
    border-bottom: 2px solid var(--grey);
    border-right: 2px solid var(--grey);
    transform: rotate(45deg);
    transition: 500ms transform;
    position: absolute;
    right: 14px;
    bottom: 17px;
    transition: all ease-in-out 0.5s;


    &.isOpened {
        border-bottom: 2px solid var(--main)!important;
        border-right: 2px solid var(--main)!important;
    }

    &.isCompleted {
        border-bottom: 2px solid var(--main)!important;
        border-right: 2px solid var(--main)!important;
    }

}


.content {
    // display: none;
    max-height: 0px;
    height: 100%;
    overflow: hidden;
    transition: all ease-in-out 0.5s;
}

.anliegen {
    font-size: 14px;
    letter-spacing: -0.17px;
    line-height: 16px;
}
