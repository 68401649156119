.dashboard {
  display: flex;
  align-items: center;
  padding: 2rem 1.6rem;
  //width: 28rem;

  cursor: pointer;

  &:hover {
    box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2),
      0 0.4rem 1.5rem -1rem rgba(38, 50, 56, 0.2),
      0 0.9rem 3rem -1.5rem rgba(38, 50, 56, 0.2),
      0 1.8rem 6rem -3rem rgba(38, 50, 56, 0.2);
  }

  &:focus {
    &:after {
      opacity: 1;
    }
  }

  &:active {
    &:after {
      opacity: 0;
    }

    box-shadow: 0 0.4rem 1rem -0.8rem rgba(38, 50, 56, 0.6);
  }

  box-shadow: 0 1.8rem 6rem -3rem rgba(38, 50, 56, 0.2);
  transition: all 0.2s linear;

  &.blurred {
    filter: blur(0.72rem);
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subtitle {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.2rem;
  font-weight: 600;
}

.title {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.6rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
  text-align: unset !important;

  @media (max-width: 896px) {
    // margin: 0px 0 43px 0;
    margin: 0;
    font-size: 14px;
    justify-content: space-between;
  }
}

.text {
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 400;
}

.icon {
  cursor: pointer;
  margin-right: 0.6rem;
}

.cardTop {
  .top {
    margin: 0;
  }
  .title {
    font-size: 4.8rem;
    letter-spacing: 0;
    line-height: 5.6rem;
  }
}
