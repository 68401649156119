@import '../../styles/config.scss';

.calendar {
  max-width: 476px;
  min-width: 476px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // @media (min-width: 2560px) {
  //   width: 33.0556vw;
  // }
  overflow: hidden;
  @media (max-width: 896px) {
    min-width: 0;
    width: calc(100vw - 32px);
    margin-top: 11px;
  }
}

.calendar .calendarTitle {
  font-size: 22px;
  line-height: 22px;
  color: var(--grey-dark);
  font-weight: bold;
  margin-bottom: 40px;
  // @media (min-width: 2560px) {
  //   font-size: 1.5278vw;
  //   line-height: 1.5278vw;
  //   margin-bottom: 2.7778vw;
  // }
}

.calendar .datePicker {
  width: 100%;
  background-color: var(--light);
  border-radius: 8px;
  max-height: 468px;
  // @media (min-width: 2560px) {
  //   width: inherit;
  //   border-radius: 0.5556vw;
  //   max-height: 32.5vw;
  // }
  @media (max-width: 896px) {
    height: 360px;
    max-height: 65vh;
    overflow: auto;
  }
}

.calendar .datePicker .choseDate {
  box-sizing: border-box;
  min-height: 468px;
  margin: 0 48px 32px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // @media (min-width: 2560px) {
  //   min-height: 32.5vw;
  //   margin: 0 3.3333vw 2.2222vw;
  // }
  @media (max-width: 896px) {
    margin: 0;
    min-height: 252px;
  }
}

.calendar .datePicker .choseDate .clue {
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  margin-bottom: 16px;

  @media (max-width: 896px) {
    margin-bottom: 0;
  }
  // @media (min-width: 2560px) {
  //   font-size: 0.9722vw;
  //   line-height: 1.1806vw;
  //   margin-bottom: 1.1111vw;
  // }

}

.calendar .datePicker .date {
  box-sizing: border-box;
  display: flex;
  //width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--grey);
  margin: 32px 48px 24px;



  @media (max-width: 896px) {
    height: 64px;
    margin: 0 13px 17px;

    &.daysHidden+.choseDate {
        min-height: 200px;
    }
  }
  // @media (min-width: 2560px) {
  //   height: 3.3333vw;
  //   border-bottom: 0.1389vw solid var(--grey);
  //   margin: 2.2222vw 3.3333vw 1.6667vw;
  // }
}

.dateParameter {
  color: var(--grey-dark);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 16px;
  text-align: center;
  // display: inline-block;
  cursor: pointer;
  // @media (min-width: 2560px) {
  //   font-size: 1.1111vw;
  //   letter-spacing: -0.0139vw;
  //   line-height: 1.1111vw;
  // }

  outline: none;
}


.test[placeholder]:empty:before {
  content: attr(placeholder);
  color: var(--main);
}

.activeDateParameter,
.activeDateParameter[placeholder]:empty:before {
  color: var(--blue-light);
}


.calendar .datePicker .confirmationDialog {
  box-sizing: border-box;
  width: 100%;
  padding: 24px 162px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  // @media (min-width: 2560px) {
  //   padding: 1.6667px 11.25vw 3.3333vw;
  // }
  @media (max-width: 896px) {
    padding: 24px 24px 48px;
  }
}

.calendar .button {
  width: 151px;
  padding: 19px 48px;
  margin: 0;
  // @media (min-width: 2560px) {
  //   width: 10.4861vw;
  //   padding: 1.3194vw 3.3333vw;
  // }
  @media (max-width: 896px) {
    text-align: center;
  }
}
