.teilPensionOverview {
    padding: 0;
    width: 100%;

    .sliderLikeLabel {
        &:first-child {
            margin-top: 18px !important;
        }
    }

    .expandableRowCapital {
        @media (max-width: 896px) {
            padding: 0 16px;
            margin-bottom: 0;

            .col2 {
                margin-right: 16px;
            }

            .col7 {
                margin-left: 0;
                margin-right: 0;
            }

            .col-4 {
                padding-left: 0;
                padding-right: 0;
            }

            .topRow {
                padding: 0;
            }
        }
    }
}
