@import '../../styles/config.scss';

.validationMessage {
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 12px;
  line-height: 17px;
  color: var(--grey);
}

.success {
  color: green;
}

.undefined {
  visibility: hidden;
}

.error {
  color: red;
}

.standard {
  //color: white;
}
