.pensionDynamics {
    position: absolute;
    left: 30px;
    top: 30px;
    height: 45px;
}

.icrPowered {
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: 58px;
}

@media (max-width: 1150px) {

    .icrPowered,
    .pensionDynamics {
        display: none;
    }
}

@media (max-width: 896px) {
    .card {
        overflow-y: auto;
        overflow-x: hidden;

        &.step1,
        &.step2 {
            .mobileAnimatable {
                // display: none;
            }
        }
    }

    .min-wind {
        min-width: 327px;
    }

    .topCard {
        padding-top: 0 !important;
        padding-bottom: 22px !important;
        height: 70px;

        img {
            height: 100%;
            max-width: 100%;
        }
    }

}

.mobilePadding {
    @media (max-width: 896px) {
        padding: 0;
    }

    @media (max-width: 327px) {
        padding: 6px;
    }
}

.card {
    .slide {

        svg,
        p {
            cursor: pointer;
        }
    }


}

.passwordVergessenButton {
    width: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;

    // @media (min-width: 2560px) {
    //   height: 2.4306vw;
    // }
    &p {
        margin-bottom: -4px;
    }

    ;
}

.title-logo {
    max-height: 48px;
    margin-bottom: 53px;

}

.pkar {
    height: 60px;
    width: auto;
}

.brain {
    width: 177px;
    height: 177px;
    margin: 54px 0 16px 0;

    @media (max-width: 896px) {
        width: 144px;
        height: 144px;
        margin-top: 0;
    }
}

.next {
    width: 267px;
    height: 56px;
}

.arrow {
    width: 10px;
    height: 10px;
}

.buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title {
    margin-bottom: 16px;
    letter-spacing: -0.18px;

    @media (max-width: 896px) {
        margin-top: 15px;
        margin-bottom: 24px;
    }
}

.title-subtitle {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 22px;
    height: 58px;

    @media (max-width: 896px) {
        min-height: 132px;
    }
}

.bigButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px !important;

    @media (max-width: 896px) {
        margin-top: 3.5rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 14px !important;
    }
}

.rectangle {
    margin-bottom: 49px;
    margin-top: 16px;
    height: 16px;

    @media (max-width: 896px) {
        margin: 16px auto;

        svg {
            // display: none;
        }

        display: block;
    }
}

.rectangle-copy {
    background-color: #263238;
}

.rectangle-copy-empty {
    height: 10px;
    width: 10px;
    border: 2px solid #263238;
    border-radius: 12px;
    margin-right: 4px;
    box-sizing: border-box;
}
