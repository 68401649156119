@import '../chartSettings.scss';

.blockPension {
    z-index: 1;

    // @media (max-width: 896px) {
    padding-right: 0 !important;
    // }

    // padding-right: $chartRightPadding !important;
    height: 100%;

    .blockElasticHeight {

        cursor: pointer;
    }

    .tooltipContainer {
        flex: 1 1 auto;
        position: relative;
    }

    .iconClickable {
        position: relative;
        cursor: pointer;
        
        &:active {
            .strokeToggle {
                stroke: var(--blue-light);
            }

            .fillToggle {
                fill: var(--blue-light);
            } 
        }

        &:hover &:focus {
            border: none;
            outline: none;

            .strokeToggle {
                stroke: var(--blue);
            }

            .fillToggle {
                fill: var(--blue);
            }
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .blockWrapper {
        transition: max-height 0.3s ease-in-out;
        // height 0.3s ease-in-out
        // top: 10px;
        // position: relative;
    }

    #barPensionLabel {}

    #barPensionGuide {
        width: 100%;
        padding-bottom: 5px;
        font-size: 12px;
        border-bottom: 2px dotted;
    }

    #barPensionPrivate {
        @extend .lightGreyGradient;
        border-top-right-radius: 0;
        // background-color: #e4e4e4;
        color: var(--main);
        position: relative;
    }

    #barPensionGov {
        @extend .blueGradient;
        border-radius: 0;
        // background-color: #c5d787;
        color: var(--main);
    }
}