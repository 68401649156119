.controls {
    .invalidPurchaseDate {
        position: relative;
        // bottom: 16px;
        font-weight: 500;
        color: var(--red);
        margin-bottom: 0;
        // padding: 0 15px;

        @media (max-width: 896px) {
            bottom: -9px;
            position: relative;
        }

        .strokeToggle {
            stroke: var(--red);
        }

        .fillToggle {
            fill: var(--red);
        }
    }

    &.bridgingImpossible {
        .bridgingPensionSlider {
            display: none;
        }
        .sliderWrapperParent, .sliderWrapper {
            flex: 1;
            max-width: 50%;
            .sliderWrapper {
                flex: 1 1 100%;
                max-width: 100%;
            }
        }
    }
}

.modal {
    .invalidPayrollClass {
        text-align: left;
        font-weight: 600;
        color: var(--red);
        display: inline-block;
        margin-bottom: 25px;
        margin-top: -35px;
    }

    .invalidPurchaseDate {
        // position: absolute;
        // bottom: 0;
        text-align: left;
        font-weight: 600;
        color: var(--red);
        display: inline-block;
        margin-bottom: 8px;
        margin-top: -14px;
        // padding: 0 15px;
        svg {
            position: relative;
            bottom: 4px;
        }

        @media (max-width: 896px) {
            bottom: -9px;
            position: relative;
        }

        .strokeToggle {
            stroke: var(--grey);
        }

        .fillToggle {
            fill: var(--grey);
        }

        &:hover {
            .strokeToggle {
                stroke: var(--blue-light);
            }
    
            .fillToggle {
                fill: var(--blue-light);
            } 
        }
    }
}

.controls .invalidPurchaseDate {
    // bottom: 50px;
}

.modal {
    .buyin {
        .invalid {
            color: var(--red);
        }
    }
}

.scrollable {
    width: 100%;
    // overflow-y: auto;
    max-height: 82px;
    margin-bottom: 8px;

    &.rightPadding {
        padding-right: 16px;

    }
}

.col-11-desktop {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;

    @media (max-width: 896px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.allRemoved {
    font-size: 16px;
    margin-right: 16px;
    font-weight: 600;

    @media (max-width: 896px) {
        font-size: 14px;
        width: 100%;
        text-align: left;
    }
}

.invalidPurchaseDate + .removeCapitalIcon {
    bottom: 52px;
}

.removeCapitalIcon {
    position: absolute;
    right: -40px;
    // bottom: 25px;
    bottom: 30px;

    &:hover {
        .strokeToggle {
            // stroke: var(--blue-light);
        }
    }

    @media (max-width: 896px) {
        top: 60px;
        right: 0;
        height: 36px;
    }
}

.capitalBuyInText {
    // left: 0;
    margin-right: auto;

    // @media (max-width: 896px) {
    //     position: absolute;
    //     top: 25px;
    //     font-size: 10px;
    //     font-weight: 500;
    //     left: 16px;
    // }
}

.buyinedit {
    .slider_val_parent {
        max-width: 115px;

        &:last-child {
            max-width: 96px !important;
        }

    }
}

.p-md-24 {
    @media (min-width: 897px) {
        padding: 0 24px;
    }
}

.capitalBuyInSlider {
    .slider_val_parent {

        @media (min-width: 897px) {
            max-width: calc((100% - 170px)/2) !important;
        }
    }

    @media (max-width: 896px) {
        &.one {
            margin-bottom: 0 !important;

            &+.scrollable {
                display: none;
            }
        }

        .slider_header {

            p {
                width: 100%;
                text-align: left;
            }

            .slider_val_parent {
                justify-content: flex-start;
                //     margin-left: 0;
                //     &:last-child {
                //         margin-left: auto;
                //     }
            }
        }
    }
}

.sliderWrapperParent {
    width: 100%;
}

.scheidungWrapper {
    display: flex;
    flex-direction: column;

    p.title {
        text-align: left;
        margin-bottom: 2.6rem;
        color: var(--main);
        font-family: "Inter";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.2px;
        line-height: 16px;
    }

    p.scheidungsdatum {
        height: 16px;
        width: 136px;
        color: #969899;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.2px;
        line-height: 16px;
      }
}

.scheidungInSlider {
    .slider_val_parent {

        @media (min-width: 897px) {
            max-width: calc((100% - 170px)/2) !important;
        }
    }

    @media (max-width: 896px) {
        &.one {
            margin-bottom: 0 !important;
            &+.scrollable {
                display: none;
            }
        }
        
        .slider_header {

            p {
                width: 100%;
                text-align: left;
            }

            .slider_val_parent {
                justify-content: flex-start;
                //     margin-left: 0;
                //     &:last-child {
                //         margin-left: auto;
                //     }
            }
        }
    }
}

input {
    &.invalid,
    &:invalid {
        &:not(:focus) {
            & + .slider_proc {
                border-color: var(--red) !important;
                color: var(--red) !important;
            }

            border-color: var(--red) !important;
            color: var(--red) !important;
            // border-width: 2px !important;
        }
    }
}

.modal .reflect-button {
    position: absolute;
    bottom: 50px;
    right: 50px;
    text-align: center;

    @media (max-width: 896px) {
        width: calc(100vw - 64px);
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
    }
}

.modal {
    .buyin {
        position: relative;
    }
    .withdrawal {
        position: relative;
    }
}
.totalRetirement {
    margin-right: 2px;
    margin-bottom: 0;
    font-weight: 500;
}

.title {
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 1.4rem;
    margin-bottom: .8rem;
    font-weight: 500;
  }
