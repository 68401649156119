@import '../../../styles/config.scss';


.years {
  width: calc(100% + 20px);
  display: flex;
  flex-flow: wrap;
  overflow-y: scroll;
  max-height: 331px;

  // @media (min-width: 2560px) {
  //   width: calc(100% + 1.3889vw);
  //   max-height: 22.9861vw;
  // }
  @media (max-width: 896px) {
    width: calc(100%);
    justify-content: center;
    overflow-y: auto;
    padding: 0.75rem 1.5rem;
    //max-height: 18vh;
  }
}

.line {
  width: 100%;
  margin: 8px 0;
  border-bottom: 1px solid #DDDDDD;
  // @media (min-width: 2560px) {
  //   margin: 0.5556vw 0;
  //   border-bottom: 0.0694vw solid var(--grey);
  // }
}

.years .year {
  width: calc(20% - 4px);
  height: 47px;
  font-size: 16px;
  color: var(--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 100ms ease;
  font-weight: 500;
  letter-spacing: -0.2px;

  @media (min-width: 897px) {
    height: 54px;
  }

  // @media (min-width: 2560px) {
  //   width: 5.2778vw;
  //   height: 3.75vw;
  //   font-size: 1.1806vw;
  //   border-radius: 0.3472vw;
  // }
}

@media (min-width: 896px) {
  .years .year:hover {
    // background-color: var(--grey);
    // color: var(--green-dark);
    color: white;
    background-color: var(--blue-light);
    font-weight: 600;
  }
}

.years .year:active {
  // background-color: var(--green-hover);
  // color: var(--green-dark);
  color: white;
  background-color: var(--blue-light);
  font-weight: 600;
}
