@import './config.scss';
@import '../assets/fonts/inter.css';


html {
  font-size: 10px;
}

h1 {
	color: var(--main);
	font-size: 2.2rem;
	font-weight: 600;
	line-height: 2.2rem;
	// @media (min-width: 2560px) {
		// font-size: 1.6444vw;
		// line-height: 1.6444vw;
	// }
}

p {
  	font-size: 1.4rem;
  	letter-spacing: -0.17px;
	line-height: 2rem;
	// @media (min-width: 2560px) {
		// font-size: 0.9556vw;
		// line-height: 1.4222vw;
	// }
}

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

label {
	color: var(--main);
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.7rem;
    white-space: normal !important;
    margin-bottom: 1rem;
}
