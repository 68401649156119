// @import '@material/react-tab/index.scss';
// @import '@material/react-tab-indicator/index.scss';
@import '../../node_modules/@material/react-tab/dist/tab.css';
@import '../../node_modules/@material/react-tab-indicator/dist/tab-indicator.css';
@import './bootstrap-reboot.css';
@import './typography.scss';
@import './config.scss';
@import './bootstrap.css';
@import './utilities.scss';

body {
    margin: 0;

    .contentContainer {
        // position: relative;
    }

    .backgroundImage {
        display: none;
        z-index: 0;
    }

    &.welcome {
        @media (max-width: 896px) {
            .container-fluid {
                padding: 0 24px;

                @media (max-width: 600px) {
                    padding: 0;
                }

                .row {
                    width: 100%;
                    max-width: 327px;
                    margin: auto;
                }
            }
        }

        .card {
            min-height: 483px;

            @media (max-width: 896px) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        .backgroundImage {
            display: block;
            width: 100vw;
            height: 100vh;
            position: fixed;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            // #root {
            //     overflow: auto;
            // }
        }
    }
}

.card {
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 18px 60px -30px rgba(38, 50, 56, 0.2);
    padding: 32px;
    width: 100%;
    max-width: 619px;
    z-index: 1;

    //   @media (min-width: 2560px) {
    // max-width: 42.9861vw;
    // padding: 2.2vw;
    //   }
    @media (max-width: 360px) {
        padding: 16px;
        min-width: auto;
        max-width: calc(100vw - 16px);
    }
}

.sidebar>.flex-grow-1 {
    max-height: 25vh;
    height: 100px;

    @media (max-width: 896px) {
        display: none;
    }

}

.dropdown input.textMaterial {
    // padding: 5px 10px;
    cursor: pointer;
}

.arrow {
    &.left {
        transform: rotate(180deg);
    }
}

.mdc-tab:focus {
    outline: 0
}

button {

    &:focus,
    &:active {
        outline: none;
    }
}

.mdc-tab__ripple:hover::before,
.mdc-tab__ripple.mdc-ripple-upgraded {
    opacity: 0;
}

.mdc-tab .mdc-tab__text-label {
    opacity: 1;
    color: var(--grey);

}

.mdc-tab.mdc-tab--active {
    .mdc-tab__text-label {
        color: var(--blue-light);
    }
}

.mdc-tab:not(.mdc-tab--active) {
    &:hover {
        .mdc-tab-indicator__content {
            opacity: 0;
        }

        &:hover {
            .mdc-tab__text-label {

                opacity: 1;
                color: var(--blue-dark);
            }
        }
    }
}

.mdc-tab--min-width {
    @media (max-width: 575px) {
        width: 50%;
    }
}

.rc-tooltip {
    opacity: 1;
    animation: opacityAnime 0.3s;
    animation-timing-function: ease-in-out;

    @keyframes opacityAnime {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    &.rc-tooltip-hidden {
        transition: opacity ease-in-out 0.3s;
        opacity: 0;
        display: block !important;
    }

    &.rc-tooltip-placement-topRight .rc-tooltip-arrow {
        right: 7px !important;
    }

    .rc-tooltip-arrow {
        border-top-color: #263238;
    }

    .rc-tooltip-inner {
        padding: 16px;
        border-radius: 8px !important;
        background-color: #263238 !important;
        box-shadow: 0 27px 100px -20px rgba(38, 50, 56, 0.2), 0 4px 15px -10px rgba(38, 50, 56, 0.2), 0 9px 30px -15px rgba(38, 50, 56, 0.2), 0 18px 60px -30px rgba(38, 50, 56, 0.2) !important;
        max-width: 284px;


        p {
            &.blockTitle {
                font-weight: 500;
                margin-top: 4px;
            }

            &.blockValue {
                margin-top: -1px;
                margin-bottom: 5px;
                font-weight: 300;
            }

            font-weight: 500;
            font-size: 12px !important;
            margin-bottom: 0;
            line-height: 19px;
            text-align: left;
        }
    }
}

.rotate-180 { transform: rotate(180deg) }
