@import '../chartSettings.scss';

.dragStarted {
    .block65plus {
        opacity: 0 !important;
    }
}

.block65plus {
    z-index: 2 !important;
    pointer-events: none;
    transition: all ease-in-out 0.3s;

    &.blockFullHeight {

        position: absolute;
        // right: 0px;
        bottom: 0;
        // padding-right: $chartRightPadding !important;
        right: 0;
        z-index: 1;
        padding-top: 0 !important;
    }

    .blockTitle {
        // visibility: hidden;
        display: none;
    }

    .blockValue {
        // visibility: hidden;
        display: none;
    }

    #barPensionierungLabelWrapperPlus {
        position: relative;
    }

    #barPensionGovWrapperPlus {
        position: relative;
    }

    #barPensionGuidePlus {
        width: calc(100% - 2px);
        padding-bottom: 5px;
        color: #263238;
        font-size: 14px;
        letter-spacing: -0.4px;
        line-height: 16px;
        // padding-right: $chartRightPadding !important;
        position: absolute;
        bottom: -4px;
        left: 5px;

        &.horizontal {
            svg {
                width: calc(100% - 6px);
                left: 6px;
                position: relative;
            }
        }
    }

    #barPensionPrivatePlus {
        border-top-right-radius: 0;
        // background-color: transparent;
        // color: #000;
        position: relative;
    }

    #barPensionGovPlus {
        // border-radius: 0;
        // background-color: transparent;
        // color: #000;
    }

    #barCapitalWithdrawalPlus {
        // background-color: transparent;
        position: absolute;
        bottom: 0;
        width: 100%;
        max-height: 40%;
    }
}
