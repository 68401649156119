@import '../../../styles/config.scss';

.days {
    width: 100%;
    display: flex;
    flex-flow: wrap;

    @media (max-width: 896px) {
        //justify-content: center;
        padding: 1rem;
        overflow-y: auto;
        //max-height: 18vh;
    }
}

.days .day {
    //width: 54px;
    //height: 54px;
    font-size: 16px;
    color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: all 100ms ease;
    width: 14.2%;
    height: 43px;
    font-weight: 500;
    letter-spacing: -0.2px;

    @media (min-width: 897px) {
        height: 54px;
    }

    // @media (min-width: 2560px) {
    //   width: 3.75vw;
    //   height: 3.75vw;
    //   font-size: 1.1806vw;
    //   border-radius: 0.3472vw;
    // }
}
@media (min-width: 896px) {
    .days .day:hover {
        // background-color: var(--grey);
        color: white;
        background-color: var(--blue-light);
        font-weight: 600;
    }
}


.days .day:active {
    // background-color: var(--green-hover);
    color: white;
    background-color: var(--blue-light);
    font-weight: 600;
}
