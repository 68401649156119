@import '../chartSettings.scss';

.blockSalary {
    // padding-left: $chartLeftPadding !important;
    height: 100%;


    // .stickyLabelContainer {
    //     @media (max-width: 896px) {
    //         // .blockTitle, .blockValue {
    //             position: fixed;
    //             left: 32px;
    //         // }
    //     }
    // }

    #barSalaryLabel {}

    #barSalaryPerc {
        @extend .lightGreyGradient;
        border-top-left-radius: 0;
        // background: linear-gradient(#D2D4D6, #E1E4E6);
        color: var(--main);
    }

    @media (max-width: 896px) {
        padding-left: 0 !important;
    }
}